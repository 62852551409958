import { ClaimStatus } from './claim'
import {
  AcceptedOrderItemAggregated,
  BuyerOrderItem,
  BuyerOrderItemAggregated,
  SellerDirectOrderItem,
  SellerOrderItem,
  SellerOrderItemAggregated,
} from './orderItem'
import { FormattedPermissions, Permissions } from './permissions'
import { SellerCompany } from './sellerCompany'

// Do not change the order of this enum as it is used to populate the filters
export enum OrderStatus {
  PENDING = 'pending',
  TO_PREPARE = 'to_prepare',
  VALIDATED = 'validated',
  INVOICED = 'invoiced',
  DRAFT = 'draft',
  CANCELLED = 'cancelled',
  INVOICING = 'invoicing',
}

export enum OrderType {
  INVOICED = 'invoiced',
  FUTURE = 'future',
  PAST = 'past',
  TODAY = 'today',
  TO_INVOICE = 'to_invoice',
}

export enum OrderActionType {
  ADD = 'add',
  EDIT = 'edit',
}

export enum OrderOriginType {
  PURCHASE_ORDER = 'purchase',
  SALES_ORDER = 'sales',
}

export type OrderActions = 'add_order_item' | 'approve' | 'cancel' | 'prepare' | 'update'
export type OrderAttributes =
  | 'buyer_company_id'
  | 'comment'
  | 'delivery_date'
  | 'delivery_location_id'
  | 'entered_price_unit_id'
  | 'gln_association_id'
  | 'items'
  | 'marketplace_id'
  | 'po_number'
  | 'price'
  | 'printed'
  | 'provider_comment_delivery_note'
  | 'provider_internal_comment_order_note'
  | 'quantity_unit_id'
  | 'quantity'
  | 'shipping_date'
  | 'shipping_location_id'
  | 'status'
  | 'tour_id'
  | 'tour_pickup_time'

export type OrderPermissions = Permissions<OrderActions, OrderAttributes>

export type OrderFormattedActions = 'addOrderItem' | 'approve' | 'cancel' | 'prepare' | 'update'
export type OrderFormattedAttributes =
  | 'buyerCompanyId'
  | 'comment'
  | 'deliveryDate'
  | 'deliveryLocationId'
  | 'enteredPriceUnitId'
  | 'glnAssociationId'
  | 'items'
  | 'marketplaceId'
  | 'poNumber'
  | 'price'
  | 'printed'
  | 'providerCommentDeliveryNote'
  | 'providerInternalCommentOrderNote'
  | 'quantity'
  | 'quantityUnitId'
  | 'shippingDate'
  | 'shippingLocationId'
  | 'status'
  | 'tourId'
  | 'tourPickupTime'

export type FormattedOrderPermissions = FormattedPermissions<
  OrderFormattedActions,
  OrderFormattedAttributes
>

export type ClaimCounts = Record<ClaimStatus, number>

interface CdOriginOrder {
  createdBy: string
  id: Id
}

export interface OrderBase {
  buyerAisleReference: string | null
  buyerCompanyId: Id
  claimCounts: ClaimCounts
  comment: string | null
  createdBy: string
  createdDate: ApiDate
  deliveryDate: ApiDate
  deliveryLocationId: Id | null
  documents: Id[]
  glnAssociationId: Id | null
  hasDesynchronizedDispatches: boolean
  id: Id
  isDeliveryNoticeAvailable: boolean
  isSellerOrder: boolean
  isSsccAvailable: boolean
  labelsPdfFilename: string | null
  lastAddedItemDate: ApiDate
  manorGrpcNumber: number | null
  marketplaceId: Id
  orderedAfterCutoff: boolean
  originBuyerCompanyId: Id
  cdOriginOrders: CdOriginOrder[]
  poNumber: string | null
  providerCommentDeliveryNote: string | null
  shippingDate: ApiDate
  shippingLocationId: Id | null
  shippingPackages: number | null
  ssccPdfFilename: string | null
  status: OrderStatus
  tourId: Id | null
}

export interface BuyerOrderBase extends OrderBase {
  /**
   * @deprecated
   * Should use the permissions instead
   */
  lockedForBuyer: boolean
  logisticMode: 'CD1' | 'CD2' | 'DIRECT' | 'CD-P' | null
  orderDate: ApiDate
  permissions: Permissions<OrderActions, OrderAttributes>
  sellerCompanyId: Id
  tourPickupTime: string | null
  type: OrderOriginType.PURCHASE_ORDER
}

export interface BuyerOrder extends BuyerOrderBase {
  items: BuyerOrderItem[]
}

export interface BuyerOrderAggregated extends BuyerOrderBase {
  items: BuyerOrderItemAggregated[]
  sellerCompany?: SellerCompany
}

export interface BuyerOrderInAction extends Omit<BuyerOrder, 'itemIds'> {
  items: BuyerOrderItem[]
}

export interface SellerDirectOrder {
  buyerCompanyId: Id
  deliveryDate: ApiDate
  items: SellerDirectOrderItem[]
  marketplaceId: Id
  poNumber: string | null
  shippingDate: ApiDate
}

export interface SellerOrderBase extends OrderBase {
  /**
   * @deprecated
   * Should use the permissions instead
   */
  canEditOrder: boolean
  canEditOrderItems: boolean
  createdByRemoteSource: boolean
  customsClearanceSiteId: Id | null
  deliveryAddress: string
  deliveryZipCode: string
  deliveryLocality: string
  deliveryCountry: string
  hubDeliveryName: string
  hubPickupName: string
  lastKmTransporterName: string
  permissions: Permissions<OrderActions, OrderAttributes>
  pickupTime: string
  poNumber: string | null
  printed: boolean
  providerInternalCommentOrderNote: string | null
  sellerCompanyId: Id
  tourPickupTime: string | null
  transporterName: string
  type: OrderOriginType.SALES_ORDER
  weightGross: number | null
}

export interface SellerOrder extends SellerOrderBase {
  items: SellerOrderItem[]
}

export interface SellerOrderRequestBody extends SellerDirectOrder {
  comment?: string | null
  customsClearanceSiteId?: Id | null
  deliveryLocationId?: Id | null
  id?: Id
  providerCommentDeliveryNote?: string | null
  providerInternalCommentOrderNote?: string | null
  shippingLocationId?: Id | null
  status: OrderStatus
  tourId: Id | null
  tourPickupTime: string | null
}

export interface SellerOrderAggregated extends SellerOrderBase {
  items: SellerOrderItemAggregated[]
}

export interface OrderToInvoice extends SellerOrderAggregated {
  items: AcceptedOrderItemAggregated[]
}

export interface SellerOrderQueryObject {
  buyerCompanyId?: Id | Id[]
  buyerName?: string
  deliveryHubId?: Id
  ecosystemId?: Id | Id[]
  fromShippingDate?: ApiDate
  limit?: number
  offset?: number
  ordering?: OrdersOrderBy[]
  orderingItems?: OrderItemsSortBy[]
  pickupHubId?: Id
  productName?: string
  status?: OrderStatus[]
  toShippingDate?: ApiDate
  waiting?: boolean
}

type OrdersOrderByAsc =
  | 'buyer_company_name'
  | 'cutoff'
  | 'delivery_date'
  | 'ecosystem_id'
  | 'id'
  | 'last_added_item_date'
  | 'shipping_date'
  | 'pickup_time'
  | 'status'

type OrdersOrderByDesc = `-${OrdersOrderByAsc}`

export type OrdersOrderBy = OrdersOrderByAsc | OrdersOrderByDesc

type PurchaseOrdersSortByAsc =
  | 'seller_company_name'
  | 'delivery_date'
  | 'status'
  | 'shipping_date'
  | 'id'

type PurchaseOrdersSortByDesc = `-${PurchaseOrdersSortByAsc}`

export type PurchaseOrdersSortBy = PurchaseOrdersSortByAsc | PurchaseOrdersSortByDesc

type BuyerOrdersSortByAsc =
  | 'last_added_item_date'
  | 'seller_company_name'
  | 'delivery_date'
  | 'status'
  | 'shipping_date'
  | 'id'

type BuyerOrdersSortByDesc = `-${BuyerOrdersSortByAsc}`

export type BuyerOrdersSortBy = BuyerOrdersSortByAsc | BuyerOrdersSortByDesc

type OrderItemsSortByAsc =
  | 'calibre'
  | 'id'
  | 'package_quantity'
  | 'package_quantity_unit'
  | 'product_category'
  | 'product_name'
  | 'line_identifier'
  | 'product_name_or_line_identifier' // Depends on created_by_remote_source

type OrderItemsSortByDesc = `-${OrderItemsSortByAsc}`

export type OrderItemsSortBy = OrderItemsSortByAsc | OrderItemsSortByDesc

export interface GetOrdersRequest extends QueryObject {
  buyerCompanyId?: Id | Id[]
  buyerName?: string
  deliveryHubId?: Id
  deliveryLocationId?: Id | null
  ecosystemId?: Id | Id[]
  fromDeliveryDate?: ApiDate
  fromShippingDate?: ApiDate
  hasDesynchronizedDispatches?: boolean
  hasOrderItemsWithQuantityKeptByCrossdockingOrganisation?: boolean
  id?: Id | Id[]
  limit?: number
  offset?: number
  ordering?:
    | OrdersOrderBy
    | OrdersOrderBy[]
    | PurchaseOrdersSortBy
    | PurchaseOrdersSortBy[]
    | BuyerOrdersSortBy
    | BuyerOrdersSortBy[]
  orderingItems?: OrderItemsSortBy[]
  pickupHubId?: Id
  productName?: string
  q?: string
  sellerCompanyId?: Id[]
  shippingLocationId?: Id | null
  showToOrders?: boolean
  status?: OrderStatus | OrderStatus[]
  toDeliveryDate?: ApiDate
  toShippingDate?: ApiDate
  tourId?: Id | null
  waiting?: boolean
  verticalId?: Id | Id[]
}
